import React from 'react'
import { navigate } from "gatsby";
import Layout from '../components/layout'

const NotFoundPage = () => (

  <Layout>
      <div className="bgimg" >
          <div className="bg-layout">
              <div className="middle">
                  No Idea What Your Doing...
                  <br/>
                  <button className="button" onClick={() => navigate(-1)}>Go Back</button>
              </div>
          </div>
      </div>
  </Layout>
)

export default NotFoundPage
